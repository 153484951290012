import { defineStore } from 'pinia'
import axios from 'axios'
import capitalize from 'lodash/capitalize'
import siteConfig from '@/config.json'
import { useCommon } from '@/hooks/useCommon'

export const useAlternativeShopStore = defineStore('AlternativeShopStore', {
  state: () => {
    return {
      shopItems: [],
      artist: null,
      shopItem: null,
      artists: [],
      productCategories: [],
      productTypes: [],
      productCategoriesBasedOnShopItems: [],
      productForms: [],
      productByArtist: [],
      selectedCategory: null,
      selectedType: null,
      selectedForm: null,
      selectedArtist: null,
      selectedTypeName: 'All products',
      sortBy: 'artistLastName',
      loadingProducts: false,
    }
  },
  getters: {
    preparedShopItems() {
      let shopItems = this.shopItems

      if (this.selectedType) {
        shopItems = shopItems.filter((item) => item.itemType === this.selectedType.toLowerCase())
      }

      if (this.selectedCategory) {
        shopItems = shopItems.filter((item) => item.categoryId == this.selectedCategory.categoryId)
      }

      if (this.selectedForm) {
        shopItems = shopItems.filter((item) => item.medium.toLowerCase() === this.selectedForm)
      }

      if (this.selectedArtist) {
        shopItems = shopItems.filter((item) => item.artistName === this.selectedArtist)
      }

      if (this.sortBy) {
        shopItems = shopItems.sort((a, b) => {
          const valueA = a[this.sortBy]
          const valueB = b[this.sortBy]

          if (typeof valueA === 'string' && typeof valueB === 'string') {
            return valueA.localeCompare(valueB)
          } else if (typeof valueA === 'number' && typeof valueB === 'number') {
            return valueA - valueB
          } else {
            // Fallback to basic string comparison if types are different
            return String(valueA).localeCompare(String(valueB))
          }
        })
      }

      return shopItems
    },
    sortByOptions() {
      return [
        { text: 'Artist Last Name', value: 'artistLastName' },
        { text: 'Type', value: 'itemType' },
        { text: 'Item Name', value: 'itemName' },
        { text: 'Medium', value: 'medium' },
        { text: 'Price', value: 'price' },
      ]
    },
    sortByText() {
      const option = this.sortByOptions.find((option) => option.value === this.sortBy)

      return option ? option.text : 'Artist Last Name'
    },
  },
  actions: {
    updateSelectedCategory(selectedCategory) {
      this.selectedCategory = selectedCategory
    },
    updateSelectedType(selectedType) {
      this.selectedType = selectedType
    },
    updateSelectedForm(selectedForm) {
      this.selectedForm = selectedForm
    },
    updateSelectedArtist(selectedArtist) {
      this.selectedArtist = selectedArtist
    },
    updateSortBy(sortBy) {
      const options = this.sortByOptions.map(option => option.value)
      this.sortBy = options.includes(sortBy) ? sortBy : 'artistName'
    },
    updateShopItem(shopItem) {
      this.shopItem = shopItem
    },
    async fetchShopItems() {
      const { getImageUrl } = useCommon()
      this.loadingProducts = true
      try {
        const { data } = await axios.get(
          `${siteConfig.apiBaseUrl}/salesitems/salesitemsallpublished`,
        )

        if (!data.ok) return

        data.results.forEach((item) => {
          const nameParts = item.artistName?.split(' ') || []
          item.artistLastName = nameParts[nameParts?.length - 1] || 'Missing'
          item.artistFirstName = nameParts?.slice(0, nameParts.length - 1)?.join(' ') || 'Name'
          item.imageUrl = item.imageUrl ? getImageUrl(item.imageUrl) : `/sites/${siteConfig.key}/${siteConfig.org.logoFilename}`
          item.images = [item.imageUrl, ...['imageUrl2', 'imageUrl3', 'imageUrl4'].reduce((acc, key) => {
            if (item[key]) {
              acc.push(getImageUrl(item[key]))
            }
            return acc
          }, [])]
          item.id = item.itemId
        })

        //

        this.shopItems = data.results.filter((item) => item.itemType !== 'ticket')
        this.loadingProducts = false
      } catch (error) {
        this.loadingProducts = false
        console.log('fetchShopItems error', error)
      }
    },
    fetchProductCategoriesAndArtistsBasedOnShopItems() {
      const categories = {}
      const artists = {}
      let shopItems = this.shopItems

      shopItems.forEach((item) => {
        if (!categories[item.itemType]) {
          categories[item.itemType] = item.itemType
        }

        if (!artists[item.artistName] && item.artistName) {
          artists[item.artistName] = item.artistName.trim()
        }
      })

      this.productByArtist = Object.values(artists).sort()
      this.productCategoriesBasedOnShopItems = Object.keys(categories)
    },
    async fetchProductFormsBasedOnShopItems() {
      try {
        // Actually using medium rather than form until form is part of salesitemsallpublished response
        this.productForms = this.shopItems.reduce((acc, item) => {
          if (!acc.includes(item.medium.toLowerCase())) {
            acc.push(item.medium.toLowerCase().trim())
          }

          return acc
        }, []).sort()
      } catch (error) {
        console.log('fetchProductsByArtistId error', error)
      }
    },
    async fetchProductCategories() {
      try {
        const { data } = await axios.get(
          `${siteConfig.apiBaseUrl}/salesitems/salesitemcategories`,
        )

        if (!data.ok) return

        this.productCategories = data.results.filter((item) => item.publish !== false)
      } catch (error) {
        console.log('fetchProductCategoryNames error', error)
      }
    },
    async fetchProductCategoryTiles() {
      const productCategories = [
        {
          image: `/sites/${siteConfig.key}/${siteConfig.assets?.shop?.defaultImage || siteConfig?.assets.logo}`,
          heading: 'Shop All Items',
          route: '/shop/products',
        },
      ]

      try {
        const { data } = await axios.get(
          `${siteConfig.apiBaseUrl}/salesitems/salesitemcategories`,
        )

        if (!data.ok) return

        productCategories.push(
          ...data.results.reduce((acc, item) => {
            if (item.publish === false) return acc
            let image = siteConfig?.assets?.shop?.categories?.[item.name] ?? siteConfig?.assets?.logo;
            acc.push({
              heading: `Shop ${capitalize(item.name)}`,
              image: `/sites/${siteConfig.key}/${image}`,
              route: '/shop/products',
              type: item.name,
            })

            return acc
          }, []),
        )

        this.productCategories = productCategories
      } catch (error) {
        console.log('fetchProductCategories error', error)
      }
    },
    async fetchProductsByArtistId(artistId) {
      this.artist = {}
      this.loadingProducts = true
      try {
        const { data } = await axios.get(
          `${siteConfig.apiBaseUrl}/reports/queries/salesitem-by-artist?artistId=${artistId}`,
        )

        if (!data.ok) return

        this.artist = data.results[0]

        await this.fetchShopItems()

        this.shopItems = this.shopItems.filter((item) => item.artistId === this.artist.id)
      } catch (error) {
        this.loadingProducts = false
        console.log('fetchProductsByArtistId error', error)
      }
    },
    async productById(productId) {
      if (!productId) return
      if (!this.shopItems.length) await this.fetchShopItems()
      const filteredItems = this.shopItems.filter((item) => item.itemId == productId)
      return filteredItems.length ? filteredItems[0] : null
    },
    async fetchArtists() {
      try {
        const { data } = await axios.get(
          `${siteConfig.apiBaseUrl}/reports/queries/salesitem-artists?types=${siteConfig.features.shop.shopCategoriesTypes}`,
        )

        if (!data.ok) return

        this.artists = data.results
      } catch (error) {
        console.log('fetchProductById error', error)
      }
    },
    clearFilters() {
      this.selectedCategory = null
      this.selectedType = null
      this.selectedForm = null
      this.selectedArtist = null

      this.productCategories = []
      this.productForms = []
      this.productByArtist = []
    },
    clearShopData() {
      this.shopItems = []
      this.artist = null
    },
  },
})
