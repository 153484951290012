import { createRouter, createWebHistory } from 'vue-router'
import { beforeCalendarEnter } from '@/helpers/calendar'
import { useUserStore } from '@/stores/UserStore'

import siteConfig from '@/config.json'

const MODULE_ROUTES = {
  classes: [
    {
      path: '/',
      redirect: siteConfig.modules.events && siteConfig.features.eventsBeforeClasses ? '/events' : '/classes',
    },
    {
      path: '/classes',
      name: 'Classes: index',
      component: () => import('./RouterView.vue'),
      meta: {
        title: 'Classes',
        ...(siteConfig.features.separateClassesAndWorkshops ? { division: 'class' } : {}),
      },
      children: [
        {
          path: '',
          name: 'Store Classes',
          component: () => import('../views/Classes/index.vue'),
        },
        {
          path: ':classId',
          name: 'Class Details',
          component: () => import('../views/Classes/ClassDetails.vue'),
          props: true,
        },
      ],
    },
    ...(siteConfig.features.separateClassesAndCamps
      ? [
          {
            path: '/camps',
            name: 'Camps: index',
            component: () => import('./RouterView.vue'),
            meta: { division: 'camp' },
            children: [
              {
                path: '',
                name: 'Store Camps',
                component: () => import('../views/Classes/index.vue'),
              },
              {
                path: ':classId',
                name: 'Camp Details',
                component: () => import('../views/Classes/ClassDetails.vue'),
                props: true,
              },
            ],
          },
        ]
      : []),
    ...(siteConfig.features.separateClassesAndWorkshops
      ? [
          {
            path: '/workshops',
            name: 'Workshops: index',
            component: () => import('./RouterView.vue'),
            meta: { division: 'workshop' },
            children: [
              {
                path: '',
                name: 'Store Workshops',
                component: () => import('../views/Classes/index.vue'),
              },
              {
                path: ':classId',
                name: 'Workshop Details',
                component: () => import('../views/Classes/ClassDetails.vue'),
                props: true,
              },
            ],
          },
        ]
      : []),
  ],
  events: [
    {
      path: '/events',
      name: 'Events: index',
      meta: {
        title: 'Events',
      },
      component: () => import('./RouterView.vue'),
      children: [
        {
          path: '',
          name: 'Store Events',
          component: () => import('../views/Events/index.vue'),
        },
        {
          path: ':eventId',
          name: 'Event Details',
          component: () => import('../views/Events/EventDetails.vue'),
          props: true,
        },
      ],
    },
  ],
  memberships: [
    {
      path: '/memberships',
      name: 'Memberships',
      meta: {
        title: 'Memberships',
      },
      component: () => import('../views/Memberships/index.vue'),
    },
  ],
  calendar: [
    {
      path: '/calendar/:timeUnit?/:date?',
      name: 'Calendar',
      component: () => import('../views/Calendar/index.vue'),
      props: true,
      beforeEnter: beforeCalendarEnter,
    },
  ],
  giftCertificates: [
    {
      path: '/gift-certificates',
      name: 'Gift Certificate',
      component: () => import('../views/Gift/index.vue'),
    },
  ],
  exhibitions: [
    {
      path: '/exhibitions',
      name: 'Exhibitions',
      component: () => import('./RouterView.vue'),
      children: [
        {
          path: '',
          name: 'Store Exhibitions',
          component: () => import('../views/Exhibitions/index.vue'),
        },
        {
          path: ':exhibitionId',
          name: 'Exhibition Details',
          component: () => import('../views/Exhibitions/ExhibitionDetails.vue'),
          props: true,
        },
      ],
    },
    {
      path: '/submissions',
      name: 'Submissions: index',
      component: () => import('../views/SubmitArtwork/index.vue'),
      children: [
        {
          path: 'new/:exhibitionId',
          name: 'Submissions: Add Entries',
          component: () => import('../views/SubmitArtwork/AddEntry.vue'),
        },
        {
          path: 'payment',
          name: 'Submissions: Enter Payment',
          component: () => import('../views/SubmitArtwork/EnterPayment.vue'),
        },
        {
          path: 'confirmation',
          name: 'Submissions: Confirmation',
          component: () => import('../views/SubmitArtwork/Confirmation.vue'),
        },
      ],
    },
  ],
  instructors: [
    {
      path: '/instructors',
      name: 'Instructors',
      meta: {
        title: 'Instructors',
      },
      component: () => import('./RouterView.vue'),
      children:
      [
        {
          path: '',
          name: 'Store Instructors',
          component: () => import('../views/Instructors/index.vue'),
        },
        {
          path: ':instructorId',
          name: 'Instructor Profile',
          component: () => import('../views/Instructors/InstructorProfile.vue'),
          props: true,
        },
      ],
    },
  ],
  shop: [
    ...(siteConfig.features?.shop?.alternativeShop
      ? [
         {
            path: '/shop',
            name: 'Shop',
            component: () => import('../views/ShopAlt/index.vue'),
            children: [
              { name: 'Shop', path: '', component: () => import('../views/ShopAlt/AllProducts.vue') },
              {
                path: 'products',
                component: () => import('./RouterView.vue'),
                children: [
                  { name: 'Shop All', path: '', component: () => import('../views/ShopAlt/AllProducts.vue') },
                  {
                    name: 'Shop Product Details',
                    path: ':id',
                    component: () => import('../views/ShopAlt/ProductDetails.vue'),
                    meta: { hideHeroImage: true },
                  },
                ],
              },
            ],
          },
        ]
      : [
          {
            path: '/shop',
            name: 'Shop',
            component: () => import('./RouterView.vue'),
            children: [
              {
                path: '',
                name: 'Store Shop',
                component: () => import('../views/Shop/index.vue'),
              },
              {
                path: ':productId',
                name: 'Product Details',
                component: () => import('../views/Shop/Details/index.vue'),
                props: true,
              },
            ],
          },
        ]),
  ],
  donations: [
    {
      path: '/donate',
      name: 'Store Donate',
      meta: {
        title: 'Donate',
      },
      component: () => import('../views/Donate/index.vue'),
    },
    {
      path: '/donate/confirmation',
      name: 'Store Donate Confirmation',
      meta: {
        title: 'Donate Confirmation',
      },
      component: () => import('../views/Donate/confirmation.vue'),
    },
  ],
}

const ALWAYS_TRUE_MODULES = [] // ['calendar'] // , 'shop'

// XXX TODO Make routes dynamic based on siteConfig and configured features/modules

// const routeGuard = (to, from, next) => {
//   const { isAuthenticated, loading, loginWithRedirect } = authPlugin

//   const verify = () => {
//     // If the user is authenticated, continue with the route
//     if (isAuthenticated.value) {
//       console.log('routeGuard: user is authenticated')
//       return next()
//     }

//     // Otherwise, log in
//     console.log('routeGuard: redirecting to login, targetUrl', to.fullPath)
//     loginWithRedirect({ appState: { targetUrl: to.fullPath } })
//   }

//   // If loading has already finished, check our auth state using `fn()`
//   if (!loading.value) {
//     return verify()
//   }

//   // Watch for the loading property to change before we check isAuthenticated
//   watchEffect(() => {
//     if (loading.value === false) {
//       return verify()
//     }
//   })
// }

const routes = []

addModuleRoutes()

if (!siteConfig.modules.classes) {
  if (siteConfig.key === 'aws') {
    routes.push({
      path: '/',
      redirect: '/exhibitions',
    })
  } else {
    routes.push({
      path: '/',
      redirect: (to) => {
        window.location.href = siteConfig.org.website
      },
    })
  }
}

if (siteConfig.key === 'chesapeake') {
  routes.push({
    path: '/open-studio',
    component: () => import('./RouterView.vue'),
    children: [
      {
        path: 'makerspace',
        name: 'MakerSpace Open Studio',
        component: () => import('../views/Classes/OpenStudio/MakerSpace.vue'),
      },
      {
        path: 'ceramics',
        name: 'Ceramics Open Studio',
        component: () => import('../views/Classes/OpenStudio/Ceramics.vue'),
      },
    ],
  })
  routes.push({
    path: '/fees',
    component: () => import('./RouterView.vue'),
    children: [
      {
        path: 'costume',
        name: 'CAC Dance Costume Fee',
        component: () => import('../views/Classes/Fees/CostumeFee.vue'),
      },
    ],
  })
}

// Sign In & Password Reset Flow
routes.push({
  path: '/sign-in',
  name: 'Sign In',
  component: () => import('../views/Auth/SignIn.vue'),
})

routes.push({
  path: '/forgot-password',
  name: 'Forgot Password',
  component: () => import('../views/Auth/ForgotPassword.vue'),
})

routes.push({
  path: '/reset-password/:token',
  name: 'Reset Password',
  component: () => import('../views/Auth/ResetPassword.vue'),
  props: true,
})

routes.push({
  path: '/activate/:token',
  name: 'Activate Account',
  component: () => import('../views/Auth/ResetPassword.vue'),
  props: true,
})

// Create New Account Flow
routes.push({
  path: '/create',
  name: 'Create: index',
  component: () => import('../views/Auth/Create/index.vue'),
  children: [
    {
      path: 'account',
      name: 'Create: Account Info',
      component: () => import('../views/Auth/Create/AccountInfo.vue'),
    },
    {
      path: 'attendee',
      name: 'Create: Attendee Profiles',
      component: () => import('../views/Auth/Create/AttendeeProfiles.vue'),
      meta: { requireAuth: true },
    },
    {
      path: 'payment',
      name: 'Create: Payment Methods',
      component: () => import('../views/Auth/Create/PaymentMethods.vue'),
      meta: { requireAuth: true },
    },
  ],
})

// Account Overview Flow
routes.push({
  path: '/account',
  name: 'Account: index',
  component: () => import('../views/Account/index.vue'),
  meta: { requireAuth: true },
  children: [
    {
      path: 'info',
      name: 'Account: Account Info',
      component: () => import('../views/Account/AccountInfo.vue'),
    },
    {
      path: 'classes',
      component: () => import('./RouterView.vue'),
      children: [
        {
          path: '',
          name: 'Account: Instructor Classes',
          component: () => import('../views/Account/MyClasses/index.vue'),
        },
        {
          path: ':classId/roster',
          name: 'Account: Roster Details',
          component: () => import('../views/Account/MyClasses/RosterDetails.vue'),
          props: true,
        },
      ],
    },
    {
      path: 'membership',
      name: 'Account: Membership Status',
      component: () => import('../views/Account/MembershipStatus.vue'),
    },
    {
      path: 'submissions',
      name: 'Account: Submission History',
      component: () => import('../views/Account/SubmissionHistory.vue'),
    },
    {
      path: 'profiles',
      name: 'Account: Saved Profiles',
      component: () => import('../views/Account/SavedProfiles.vue'),
    },
    {
      path: 'payment-methods',
      name: 'Account: Payment Methods',
      component: () => import('../views/Account/PaymentMethods.vue'),
    },
    {
      path: 'order-history',
      name: 'Account: Order History index',
      component: () => import('./RouterView.vue'),
      children: [
        {
          path: '',
          name: 'Account: Order History Table',
          component: () => import('../views/Account/OrderHistory/index.vue'),
        },
        {
          path: ':orderId',
          name: 'Account: Order Details',
          component: () => import('../views/Account/OrderHistory/OrderDetails.vue'),
          props: true,
        },
      ],
    },
    {
      path: 'reports',
      name: 'Account: Reports',
      component: () => import('../views/Account/Reports.vue'),
    },
  ],
})

// Checkout Flow
routes.push({
  path: '/checkout',
  name: 'Checkout: index',
  meta: { requireAuth: true },
  component: () => import('../views/Checkout/index.vue'),
  children: [
    {
      path: 'cart',
      name: 'Checkout: Review Cart',
      component: () => import('../views/Checkout/ReviewCart/index.vue'),
    },
    {
      path: 'terms',
      name: 'Checkout: Terms Acceptance',
      component: () => import('../views/Checkout/TermsAcceptance.vue'),
    },
    {
      path: 'payment',
      name: 'Checkout: Payment Info',
      component: () => import('../views/Checkout/PaymentInfo.vue'),
    },
    {
      path: 'confirmation',
      name: 'Checkout: Confirmation',
      component: () => import('../views/Checkout/Confirmation.vue'),
    },
  ],
})

if (siteConfig.key === 'snowfarm') {
  // if (!siteConfig.modules.classes) {
  //   routes.push({
  //     path: '/',
  //     // redirect: '/onboarding/test'
  //     redirect: (to) => {
  //       window.location.href = 'https://snowfarm.org'
  //     }
  //   })
  // }

  // Instructor Onboarding
  routes.push({
    path: '/onboarding/:year/:instructorId',
    name: 'Onboarding Root With Year',
    component: () => import('../views/InstructorOnboarding/index.vue'),
    children: [
      {
        path: 'contract',
        component: () => import('../views/InstructorOnboarding/SignContract.vue'),
      },
      {
        path: 'confirmation',
        component: () => import('../views/InstructorOnboarding/SignContract.vue'),
      },
      {
        path: 'status',
        component: () => import('../views/InstructorOnboarding/Status.vue'),
      },
      {
        path: 'forms/:form',
        component: () => import('../views/InstructorOnboarding/Form.vue'),
      },
      {
        path: 'forms/:form/:recordId',
        component: () => import('../views/InstructorOnboarding/Form.vue'),
      },
    ],
  })

  // Instructor Onboarding
  routes.push({
    path: '/onboarding/:instructorId',
    name: 'Onboarding Root',
    component: () => import('../views/InstructorOnboarding/index.vue'),
    children: [
      {
        path: 'contract',
        component: () => import('../views/InstructorOnboarding/SignContract2023.vue'),
      },
      {
        path: 'confirmation',
        component: () => import('../views/InstructorOnboarding/SignContract2023.vue'),
      },
      {
        path: 'status',
        component: () => import('../views/InstructorOnboarding/Status.vue'),
      },
      {
        path: 'forms/:form',
        component: () => import('../views/InstructorOnboarding/Form.vue'),
      },
      {
        path: 'forms/:form/:recordId',
        component: () => import('../views/InstructorOnboarding/Form.vue'),
      },
    ],
  })
}

routes.push({
  path: '/forms/:slug',
  name: 'Form',
  meta: { hideNav: true },
  component: () => import('../views/Forms/index.vue'),
})

// if (siteConfig.key === 'kershaw') { // XXX
routes.push({
  path: '/reports/:slug',
  name: 'Report',
  meta: { hideNav: true, requireAuth: true },
  component: () => import('../views/Reports/index.vue'),
})
// }

if (siteConfig.key === 'kirkland') {
  routes.push({
    path: '/docs',
    component: () => import('./RouterView.vue'),
    children: [
      {
        path: 'contracts',
        name: 'Docs: Instructor Contract',
        component: () => import('../views/Docs/InstructorContract/index.vue'),
        children: [
          {
            path: ':instructorId',
            component: () => import('../views/Docs/InstructorContract/Contract.vue'),
          },
          {
            path: ':instructorId/confirmation',
            component: () => import('../views/Docs/InstructorContract/Confirmation.vue'),
          },
        ],
      },
    ],
  })
}

routes.push({
  path: '/cart-add/:userId/:itemType/:itemId',
  name: 'CartAdd',
  meta: { hideNav: true },
  component: () => import('../views/Account/CartAdd.vue'),
})

routes.push({
  path: '/:catchAll(.*)',
  name: 'Not Found',
  // meta: {hideNav: true},
  component: () => import('../views/NotFound/index.vue'),
})

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0 }
    }
  },
})

// Example
// router.beforeEach((to, from, next) => {
//   // we wanted to use the store here
//   if (store.isLoggedIn) next()
//   else next('/login')
// })

router.beforeEach((to) => {
  // console.log('beforeEach to', to)
  const userStore = useUserStore()

  if (to.meta.requireAuth) {
    // XXX need better validation here
    let session
    try {
      const auth = localStorage.getItem(`${siteConfig.key}Auth`)
      if (auth) {
        session = JSON.parse(auth)
      }
    } catch (error) {
      console.log('error reading session data', error)
    }

    if (!userStore.loggedIn && !session) {
      userStore.redirectTo = to.fullPath
      return '/sign-in?backTo=' + to.fullPath
    }
  }
})

router.afterEach((to) => {
  // console.log('afterEach to', to)
  const pageview = {
    event: 'pageview',
    pagePath: window.location.pathname,
    pageTitle: siteConfig.org.name,
  }
  // console.log('pageview', pageview)

  window.dataLayer = window.dataLayer || []
  window.dataLayer.push(pageview)
})

export default router

function addModuleRoutes() {
  Object.entries(MODULE_ROUTES).forEach(([module, routeList]) => {
    if (siteConfig.modules[module] || ALWAYS_TRUE_MODULES.includes(module)) {
      routeList.forEach((route) => {
        if (route.children) {
          route.children = route.children.map((child) => ({
            ...child,
            meta: { title: route.meta?.title || '' },
          }))
        }
        routes.push(route)
      })
    }
  })
}
