import { acceptHMRUpdate, defineStore } from 'pinia'
import axios from 'axios'
import siteConfig from '@/config.json'

import { getAge, timestampToShortDateText, usDateFormatToYYYYMMDD } from '@/helpers/datetime'

import { useClassStore } from '@/stores/ClassStore'
import { useUserStore } from '@/stores/UserStore'
import { useAlternativeShopStore } from '@/stores/AlternativeShopStore'

export const useCartStore = defineStore('CartStore', {
  state: () => {
    return {
      items: [],
      loading: false,
      discountCode: null,
      discounts: [],
      salesItems: [],
      openedCart: false,
      shippingItemPrice: 0,
      shippingItemCategory: 0,
      totalTax: 0,
      shipping: 0,
      shouldPickupItems: Boolean(localStorage.getItem('shouldPickupItems')),
      campaign: null,
      campaignId: null,
    }
  },

  getters: {
    checkoutNotAllowedMessage() {
      if (siteConfig.features.requireUniqueAttendeesForSameClass) {
        const dupes = this.items.filter((item) => item.duplicateAttendee)
        if (dupes.length) {
          return 'You have the same person in the same class more than once. Please remove the duplicates.'
        }
      }
      if (siteConfig.features.enforceClassAgeRestrictions) {
        const ageStatuses = this.items.map((item) => this.ageStatus(item)).filter(Boolean)
        if (ageStatuses.length) {
          return ageStatuses.join(' ')
        }
      }
      for (let item of this.items) {
        if (item.type !== 'account-payment' && item.type !== 'donation' && item.type !== 'inventory' && !item.hidePersonPicker && !item.personId) {
          return 'Please select a person for each item where required.'
        }
      }
      return ''
    },
    checkoutAllowed() {
      return !this.checkoutNotAllowedMessage
    },

    shouldNotAddDonationAutomatically() {
      return sessionStorage.getItem('shouldNotAddDonationAutomatically')
    },

    donationAmount() {
      const donationItemAmount = this.itemsForAccount.find((item) => item.type === 'donation')
      return donationItemAmount ? donationItemAmount.effectiveCost : 0
    },

    haveMembershipInCart() {
      let membershipInCart = this.items.find((item) => item.type === 'membership')
      console.log('membershipInCart', membershipInCart)
      if (membershipInCart) return true
      return false
    },
    membershipsInCart() {
      return this.items.filter((item) => item.type === 'membership')
    },
    inventoryInCart() {
      return this.items.filter((item) => item.type === 'inventory').length > 0
    },
    classPersonCounts() {
      const counts = {}
      this.items.forEach((item) => {
        if (item.type === 'class' && item.personId) {
          const key = `${item.catalogId}:${item.personId}`
          counts[key] = counts[key] || 0
          counts[key] += 1
        }
      })
      return counts
    },
    itemsForAccount() {
      const userStore = useUserStore()

      const items = this.items.map((item) => {
        // A person with a membership that provides a discount
        let isDiscountMember = false

        if (userStore.isActiveMemberWithDiscount(item.personId)) {
          isDiscountMember = true
        } else if (this.membershipsInCart.length) {
          if (this.membershipsInCart.find((membership) => membership.household === true && membership.discounts)) {
            isDiscountMember = true
          } else if (
            this.membershipsInCart.find((membership) => membership.personId === item.personId && membership.discounts)
          ) {
            isDiscountMember = true
          }
        }

        // Used for percent based membership discounts
        const discountPercentage = userStore.discountPercentForUser(item.personId, item.type)
        if (isDiscountMember) {
          item.effectiveCost = discountPercentage ? parseFloat(item.nonMemberCost) * (1 - discountPercentage / 100) : parseFloat(item.memberCost)
        } else {
          item.effectiveCost = parseFloat(item.nonMemberCost)
        }

        item.effectiveCostWithoutFees = item.effectiveCost

        if (item.fee) {
          item.effectiveCost += item.fee
        }

        // find discount for this item
        const discount = this.discounts.find((discount) => discount.itemLinkId === item.itemLinkId)
        if (discount) {
          item.effectiveCost = Math.max(0, item.effectiveCost - discount.discount)
          item.discount = discount.discount
          item.exception = discount.discount
          item.notes = discount.itemNotes
        }

        if (item.type === 'class') {
          const key = `${item.catalogId}:${item.personId}`
          item.duplicateAttendee = item.personId && this.classPersonCounts[key] > 1;
        }

        return item
      })

      return items
    },

    total() {
      let total = 0
      this.itemsForAccount.forEach((item) => (total += item.effectiveCost))

      // total = Math.max(0,  total -= this.discounts.reduce((acc, discount) => acc + discount.discount, 0))

      if (this.totalTax) {
        total += this.totalTax
      }

      if (this.shipping && !this.shouldPickupItems && !localStorage.getItem('shouldPickupItems')) {
        total += this.shipping
      }

      return total
    },
    classesTotal() {
      let total = 0
      this.itemsForAccount.filter((item) => item.type === 'class').forEach((item) => (total += item.effectiveCost))
      return total
    },
    inventoryTotal() {
      let total = 0
      this.itemsForAccount.filter((item) => item.type === 'inventory').forEach((item) => (total += item.effectiveCost))
      return total
    },
    chargesTotal() {
      let total = 0
      this.itemsForAccount.filter((item) => item.type === 'charge' && item.title !== 'Processing Fee').forEach((item) => (total += item.effectiveCost))
      return total
    },
    membershipsTotal() {
      let total = 0
      this.itemsForAccount.filter((item) => item.type === 'membership').forEach((item) => (total += item.effectiveCost))
      return total
    },
    donationsTotal() {
      let total = 0
      this.itemsForAccount.filter((item) => item.type === 'donation').forEach((item) => (total += item.effectiveCost))

      return total
    },
    processingFeeTotal() {
      let total = this.classesTotal + this.inventoryTotal + this.chargesTotal + this.membershipsTotal + (siteConfig.features.donationProcessingFee ? this.donationsTotal : 0)
      const rate = siteConfig.features.processingFeeRate
      let fee = 0
      if (rate) {
        fee = Math.max((total * rate), 0.00)
      }
      return fee
    },
    accountPaymentsTotal() {
      let total = 0
      this.itemsForAccount
        .filter((item) => item.type === 'account-payment')
        .forEach((item) => (total += item.effectiveCost))
      return total
    },
    certificatesTotal() {
      let total = 0
      this.itemsForAccount
        .filter((item) => item.type === 'gift-certificate')
        .forEach((item) => (total += item.effectiveCost))
      return total
    },
    taxes() {
      if (!siteConfig.features.taxRate) return 0
      let total = 0
      this.itemsForAccount
        .filter((item) => item.taxable)
        .forEach((item) => (total += (item.effectiveCost * siteConfig.features.taxRate) / 100))
      this.totalTax = total
      return total
    }
  },

  actions: {
    init() {
      this.restoreCart()
      this.filterOutBadItems()
      this.calculateShippingPrice()
    },
    ageStatus(item) {
      if (item.type !== 'class') return null
      let age, person, cls
      if (item.personId) {
        person = useUserStore().userProfile(item.personId)
        cls = useClassStore().loadedClassById(item.catalogId)

        if (person && person.dob) {
          try {
            age = getAge(usDateFormatToYYYYMMDD(person.dob))
          } catch (e) {
            console.log('caught age parsing error', e)
          }
        }
      }

      if (age && person && cls && cls.minAge && cls.maxAge) {
        if (age > cls.maxAge || age < cls.minAge) {
          return `${cls.shortTitle} is for ${cls.ages.toLowerCase()}`
        }
      }

      return null
    },
    itemCodeToItem(code) {
      return this.salesItems.find((item) => item.item_code === code)
    },
    exhibitionSubmissions() {
      return this.items.filter((item) => item.type === 'exhibition')
    },
    getExhibitionSubmission(id) {
      return this.items.find((item) => item.type === 'exhibition' && item.id == id)
    },
    updateShouldPickupItems(value) {
      value ? localStorage.setItem('shouldPickupItems', true) : localStorage.removeItem('shouldPickupItems')
      this.shouldPickupItems = value
    },
    updateShouldNotAddDonationAutomatically(value = true) {
      sessionStorage.setItem('shouldNotAddDonationAutomatically', value)
    },
    donationItem() {
      return this.items.find((item) => item.type === 'donation')
    },
    updateDonationsTotal(donation) {
      let donationItem = donation || this.donationItem()
      if (!donationItem) return
      donation = {...donationItem, ...this.campaign}

      if (!this.donationItem()) {
        this.addItem(donation)
      } else {
        this.items = this.items.reduce((acc, item) => {
          if (item.type === 'donation') {
            item = { ...item, ...donation }
          }

          acc.push(item)
          return acc
        }, [])
      }

      this.saveItemsToLocalStorge()
    },
    updateCampaign(campaign) {
      this.campaign = { ...campaign }
      this.updateDonationsTotal()
    },
    async fetchSalesItems() {
      try {
        const response = await axios.get(`${siteConfig.apiBaseUrl}/reports/queries/salesitem-quantities`)
        if (response.data.ok) {
          this.salesItems = response.data.results
          }
      } catch (e) {
        console.log('fetchSalesItems error', e)
      }
    },

    removeDiscountCode() {
      this.discountCode = null
      this.discounts = []
    },

    async applyDiscountCode(code) {
      this.removeDiscountCode()
      if (!code) return
      this.discountCode = code
      const itemCodeMap = {
        'class': 'CATALOG',
        'inventory': 'INVENTORY',
        'membership': 'MEMBERSHIP',
        'exhibition': 'EXHIBITION',
        'ticket': 'TICKET',
        'donation': 'DONATION',
        'charge': 'OTHERCHARGE',
        'account-payment': 'ACCTPAYMENT',
      }
      const items =  { items: this.items.map ((item) => {
            const itemType = itemCodeMap[item.type]
            return {
              "discountCartItem":
                {
                  "itemLinkId": item.itemLinkId,
                  "registrantId": item.personId,
                  "ownerId": item.personId,
                  "itemType": itemType,
                  "itemId": item.itemId,
                  "scheduleId": item.activityDayId,
                  "coupon": code
                }
            }
          })
      }

      const {data} = await axios.post(`${siteConfig.apiBaseUrl}/discount-cart-items`, items)
      this.discounts = data.data?.filter((discount) => discount.discount > 0) || []
      return (this.discounts.length ? "Discount code applied successfully!" : "No items in cart are eligible for this discount code")
    },


    restoreCart() {
      const cartRaw = localStorage.getItem(`${siteConfig.key}Cart`)
      if (!cartRaw) return
      try {
        const cart = JSON.parse(cartRaw)
        if (cart.items) {
          this.items = cart.items
        }
        if (cart.discountCode) {
          this.applyDiscountCode(cart.discountCode)
        }

        // XXX temporary, can remove later.
        this.items.forEach((item) => {
          if (item.type === 'membership' && item.discount === undefined) {
            item.discount = true
          }
        })
      } catch (error) {
        console.log('error restoring cart', error)
      }
    },
    saveItemsToLocalStorge() {
      localStorage.setItem(
        `${siteConfig.key}Cart`,
        JSON.stringify({
          items: this.items.filter((item) => item.type !== 'exhibition'), // exhibit item images are too large
          discountCode: this.discountCode,
        })
      )
    },
    async calculateShippingPrice() {
      this.shipping = 0
      let inventoryItems = this.items.filter((item) => item.type === 'inventory');
      let shippingCategories = inventoryItems
        .map((item) => item.shippingCategory)
        .filter((value) => value !== null && value !== undefined && value !== '' && value !== 0)
      if (shippingCategories.length > 0){
        try {
          let result = await axios.get(`${siteConfig.apiBaseUrl}/salesitems/getshippingcost/${shippingCategories.join(',')}`)
          if (result.data.ok) {
            this.shipping = Number(result.data.results)
          }
        }
        catch (error) {
          console.log('error calculating shipping price', error)
        }
      }
    },
    addItem(item) {
      let cartId = Date.now()
      const itemLinkId = Date.now()
      this.items.push({ ...item, cartId: cartId, itemLinkId: itemLinkId})
      this.saveItemsToLocalStorge()
      return cartId
    },
    updatePersonIdForItem({ cartId, personId, name}) {
      console.log('updatePersonIdForItem cartId', cartId, 'personId', personId)
      const item = this.items.find((item) => item.cartId === cartId)
      if (item) {
        item.personId = personId
        if (item.type === 'ticket') { item.guestName = name }
      }
      this.updateRegistrationFees()
      this.saveItemsToLocalStorge()
    },
    addExhibitionSubmission(json_data) {
      const userStore = useUserStore()
      // data is sent as json string because of the imageUrl which is a large base64 string
      const data = JSON.parse(json_data)
      const item = {
        ...data,
        type: 'exhibition',
        eventId: data.exhibit.id,
        itemId: data.exhibit.id,
        operator: userStore.account?.firstName + ' ' + userStore.account?.lastName,
        personId: userStore.account?.personId,
        subtitle: `Submission to: ${data.exhibit.title}<br>
                  Dates:
                  ${ data.exhibit.start_date_fmt } -
                  ${ data.exhibit.end_date_fmt }<br>
                  Price: $${data.price}<br>
                  Dimensions: ${data.dimensions}`,
      }
      this.addItem(item)
      this.updateExhibitionPricing()
      return true
    },

    // For each exhibit item if there is another of the same type in the cart, set the price to the price2 value otherwise set it to the price value
    updateExhibitionPricing() {
      const items = this.items.filter((item) => item.type === 'exhibition')
      // group all items by eventId
      const groupedItems = {}
      items.forEach((item) => {
        if (!groupedItems[item.eventId]) {
          groupedItems[item.eventId] = []
        }
        groupedItems[item.eventId].push(item)
      })
      // for each group set the price for all but the first to price2
      Object.values(groupedItems).forEach((group) => {
        group?.forEach((item, index) => {
          if (index > 0) {
            item.nonMemberCost = item.exhibit.fee2
            item.memberCost = item.exhibit.memFee2
          } else {
            item.nonMemberCost = item.exhibit.fee1
            item.memberCost = item.exhibit.memFee1
          }
        })
      })
      this.saveItemsToLocalStorge()
    },
    duplicatesOfClass(cls, scheduleId = null){
      if (!cls.catalogId){
        return
      }
      if (scheduleId) {
        return this.items.filter((item) => item.activityDayId === scheduleId)
      }
      else{
        return this.items.filter((item) => item.catalogId === cls.catalogId)
      }
    },
    seatAvailableIncludingCart(cls, scheduleId = null){
      if (scheduleId) {
        const classDay = cls.classDays.find((d) => d.scheduleId === scheduleId)
        const seatsLeft = classDay.limit - classDay.daysSold - this.duplicatesOfClass(cls, scheduleId)?.length || 0 < 0
        console.log('seats left for session including cart: ', seatsLeft)
        return seatsLeft
      }
      else {
        const seatsLeft = cls.seatsAvailable - this.duplicatesOfClass(cls)?.length || 0 < 0
        console.log('seats left including cart: ', seatsLeft)
        return seatsLeft
      }
    },
    addClass(id, opts = {}) {
      const classStore = useClassStore()
      const userStore = useUserStore()

      let classDetails = opts.details
      let override = false

      if (classDetails) {
        override = true
      } else {
        classDetails = classStore.openClasses.find((c) => c.catalogId === id)
      }

      if (!classDetails) {
        return
      }

      if(!this.seatAvailableIncludingCart(classDetails)){
        console.log('No seats available for class', id)
        return
      }
      console.log('addClass', id, 'classDetails', classDetails)

      const subtitle = [classDetails.dateSummary, classDetails.timeSummary, classDetails.instructorName ? `Instructor: ${classDetails.instructorName}` : ''].filter(Boolean).join('<br>')
      const item = {
        type: 'class',
        catalogId: classDetails.catalogId,
        itemId: classDetails.catalogId,
        personId: !classDetails.membersOnly || userStore.isActiveMember ? userStore.account?.personId : null, // If not a member force selecting one in the cart
        title: classDetails.shortTitle,
        subtitle: subtitle,
        tuitionId: classDetails.tuitionId,
        glclass: classDetails.glclass,
        glsubclass: classDetails.glsubclass,
        memberCost: classDetails.costMember,
        nonMemberCost: classDetails.costNon,
        membersOnly: classDetails.membersOnly,
        imageUrl: classDetails.imageUrl,
        timeSummary: classDetails.timeSummary,
        dateSummary: classDetails.dateSummary,
        partialSale: classDetails.daySale,
      }

      if (override) {
        item.override = true
      }

      if (classDetails.fee) {
        item.fee = classDetails.fee
        item.feeId = classDetails.feeId
      }
      return this.addItem(item)
    },
    addClassDay(id, scheduleId, details = null) {
      const classStore = useClassStore()
      const userStore = useUserStore()

      let classDetails = details
      let override = false

      if (classDetails) {
        override = true
      } else {
        classDetails = classStore.openClasses.find((c) => c.catalogId === id)
      }

      if (!classDetails) {
        return
      }

      const classDay = classDetails.classDays.find((d) => d.scheduleId === scheduleId)

      if (!classDay) {
        console.error('Could not find classDay with scheduleId', scheduleId, 'for catalogId', id)
        return
      }

      if(!this.seatAvailableIncludingCart(classDetails, scheduleId)){
        console.log('No seats available for class', id, 'session', scheduleId)
        return
      }

      const subtitle = [classDay.dateLabel, classDetails.instructorName ? `Instructor: ${classDetails.instructorName}` : ''].filter(Boolean).join('<br>')

      const item = {
        type: 'class',
        activityDayId: scheduleId,
        catalogId: classDetails.catalogId,
        itemId: classDetails.catalogId,
        personId: userStore.account?.personId,
        title: classDetails.shortTitle,
        subtitle: subtitle,
        memberCost: classDetails.sessionCostMem,
        nonMemberCost: classDetails.sessionCostNon,
        membersOnly: classDetails.membersOnly,
        imageUrl: classDetails.imageUrl,
        dateLabel: classDay.dateLabel
      }

      if (override) {
        item.override = true
      }

      if (classDetails.feePerSession) {
        item.fee = classDetails.feePerSession
        item.feeId = classDetails.feeId
      }

      return this.addItem(item)
    },
    addWaitlistClass(id) {
      const classStore = useClassStore()
      const userStore = useUserStore()

      const classDetails = classStore.openClasses.find((c) => c.catalogId === id)
      const subtitle = ['Wait-list', classDetails.dateSummary, classDetails.timeSummary].filter(Boolean).join('<br>')
      const item = {
        type: 'class',
        catalogId: classDetails.catalogId,
        personId: userStore.account?.personId,
        title: classDetails.shortTitle,
        subtitle: subtitle,
        memberCost: 0,
        nonMemberCost: 0,
        imageUrl: classDetails.imageUrl,
        membersOnly: classDetails.membersOnly,
        joinWaitlist: true,
      }
      return this.addItem(item)
    },
    addWaitlistClassDay(id, scheduleId) {
      const classStore = useClassStore()
      const userStore = useUserStore()

      const classDetails = classStore.openClasses.find((c) => c.catalogId === id)
      const classDay = classDetails.classDays.find((d) => d.scheduleId === scheduleId)

      if (!classDay) {
        console.error('Could not find classDay with scheduleId', scheduleId, 'for catalogId', id)
        return
      }
      const subtitle = ['Wait-list', classDay.dateLabel].filter(Boolean).join('<br>')
      const item = {
        type: 'class',
        activityDayId: scheduleId,
        catalogId: classDetails.catalogId,
        itemId: classDetails.catalogId,
        personId: userStore.account?.personId,
        title: classDetails.shortTitle,
        subtitle: subtitle,
        memberCost: 0,
        nonMemberCost: 0,
        imageUrl: classDetails.imageUrl,
        membersOnly: classDetails.membersOnly,
        joinWaitlist: true,
        dateLabel: classDay.dateLabel
      }

      return this.addItem(item)
    },
    addMembership(membership, addon, autoRenew = false) {
      const userStore = useUserStore()
      const item = {
        type: 'membership',
        categoryId: membership.categoryId,
        itemId: membership.categoryId,
        discounts: membership.discounts,
        household: membership.household,
        personId: userStore.account?.personId,
        title: membership.title,
        memberCost: membership.price,
        nonMemberCost: membership.price,
        imageUrl: membership.image,
        fee: addon?.price,
        addOnId: addon?.id,
        autoRenew: autoRenew,
        subtitle: autoRenew ? 'Auto Renewing' : '',
        glAccount: siteConfig.glAccounts?.membership,
      }
      return this.addItem(item)
    },
    addTicket(event, session, tier) {
      const userStore = useUserStore()
      const item = {
        type: 'ticket',
        code: event.code,
        scheduleId: session.scheduleId,
        eventId: event.teventId,
        ticketId: event.ticketIdForScheduleId[session.scheduleId],
        itemId: event.ticketIdForScheduleId[session.scheduleId],
        personId: userStore.account?.personId,
        glacct: event.glacct,
        glclass: event.glclass,
        glsubclass: event.glsubclass,
        title: event.name,
        subtitle: `${timestampToShortDateText(session.date)} – ${tier.name}`,
        memberCost: tier.cost,
        nonMemberCost: tier.cost,
        imageUrl: event.imageUrl,
      }
      console.log('TEST: addTicket', item)
      return this.addItem(item)
    },
    addGiftCertificate(data) {
      const item = {
        type: 'gift-certificate',
        title: `$${data.amount.toFixed(2)} Gift Certificate`,
        subtitle: data.recipientName,
        memberCost: data.amount,
        nonMemberCost: data.amount,
        recipientName: data.recipientName,
        imageUrl: `/sites/${siteConfig.key}/${siteConfig.assets.giftCertificateImage}`,
        hidePersonPicker: true,
        data,
      }
      return this.addItem(item)
    },
    addAccountPayment(data) {
      const userStore = useUserStore()
      const item = {
        type: 'account-payment',
        title: 'Account balance payment',
        subtitle: '',
        items: userStore.accountItems?.map((ai) => {
          return {
            name: ai.lineItem.itemName,
            amountDue: ai.lineItem.currTotalAmt - ai.lineItem.cumulativePaid,
          }
        }),
        memberCost: data.amount,
        nonMemberCost: data.amount,
        imageUrl: `/sites/${siteConfig.key}/icon.png`,
        data,
      }

      let existing = this.items.find((item) => item.type === 'account-payment')
      if (existing) {
        this.removeItem(existing.cartId)
      }
      return this.addItem(item)
    },
    addInventory(data, opts = {}) {
      const userStore = useUserStore()
      const item = {
        type: 'inventory',
        inventoryId: data.inventoryId,
        itemId: data.inventoryId,
        itemCode: data.itemCode,
        glAccount: data.glacct,
        personId: data.personId || userStore.account?.personId,
        recurring: data.recurring || false,
        title: data.title,
        subtitle: data.subtitle,
        memberCost: data.memberCost,
        nonMemberCost: data.nonMemberCost,
        imageUrl: data.imageUrl,
        parentId: data.parentId,
        removable: data.removable === false ? false : true,
        hidePersonPicker: data.hidePersonPicker,
      }
      return this.addItem(item)
    },
    async addShopItem(data) {
      const userStore = useUserStore()

      let item = {
        glAccount: data.glacct,
        itemId: data.id,
        title: data.itemName,
        type: 'inventory',
        effectiveCost: data.price,
        memberCost: data.price,
        nonMemberCost: data.price,
        shippingCategory: data.shippingCategory,
        personId: userStore.account?.personId,
        imageUrl: data.imageUrl,
        id: data.id,
        taxable: data.taxable,
      }
      const cardId = this.addItem(item)
      // Recalculate the shipping price for the cart to include the new item
      await this.calculateShippingPrice()
      return cardId
    },
    addCharge(data, opts = {}) {
      const userStore = useUserStore()
      const item = {
        type: 'charge',
        itemId: data.itemId,
        glAccount: data.glAccount,
        personId: data.personId || userStore.account?.personId,
        title: data.title,
        subtitle: data.subtitle,
        memberCost: data.memberCost,
        nonMemberCost: data.nonMemberCost,
        imageUrl: data.imageUrl,
        cartId: Date.now(),
        parentId: data.parentId,
        removable: data.removable !== false,
      }
      return this.addItem(item)
    },

    updateRegistrationFees() {
      if (siteConfig.key !== 'snowfarm') return

      const userStore = useUserStore()

      const imageUrl = `/sites/${siteConfig.key}/icon.png`

      this.items
        .filter((item) => item.type === 'class')
        .forEach((item) => {
          // 2388 is high school app fee which is done using a class registration
          if (item.catalogId === 2388 || item.catalogId === 2833) return

          if (item.joinWaitlist) return

          if (!item.personId) return
          const firstDayOfYear = new Date(new Date().getFullYear(), 0, 1).toISOString().split('T')[0]
          const paidRegFee = userStore.registrationFees.find((fee) => fee.registrant_id === item.personId && fee.item_trans_date >= firstDayOfYear)

          if (paidRegFee) return

          const regFeeInCart = this.items.find(
            (feeItem) => feeItem.title === 'Registration Fee' && feeItem.personId === item.personId
          )

          if (!regFeeInCart) {
            const regFeeItem = {
              itemId: 1099,
              personId: item.personId,
              glAccount: '4151',
              title: 'Registration Fee',
              subtitle: 'Annual Fee',
              memberCost: 35,
              nonMemberCost: 35,
              imageUrl,
              removable: false,
            }
            this.addCharge(regFeeItem)
          } else {
            regFeeInCart.removable = false
          }
        })

      this.items
        .filter((feeItem) => feeItem.title === 'Registration Fee')
        .forEach((fee) => {
          const classItemsForPerson = this.items.filter(
            (item) => item.type === 'class' && item.personId === fee.personId
          )
          if (!classItemsForPerson.length) {
            fee.removable = true
          }
        })
    },

    removeProcessingFee() {
      const processingFeeItem = this.items.find((item) => item.type === 'charge' && item.title === 'Processing Fee')
      if (processingFeeItem) {
        this.removeItem(processingFeeItem.cartId)
      }
    },
    addProcessingFee(cost) {
      if (!cost || cost <= 0) return
      this.addCharge({
        title: 'Processing Fee',
        subtitle: `For credit card payments`,
        memberCost: cost,
        nonMemberCost: cost,
        glAccount: siteConfig.glAccounts?.processingFee,
        glclass: siteConfig.glClasses?.processingFee,
        removable: false,
      })
    },
    updateProcessingFee(cost) {
      this.removeProcessingFee()
      this.addProcessingFee(cost)
    },
    removeItem(cartId) {
      const alternativeShopStore = useAlternativeShopStore()
      if (!cartId) {
        this.updateShouldNotAddDonationAutomatically()
        this.items = this.items.filter((item) => item.type !== 'donation')
        this.saveItemsToLocalStorge()
        return
      }

      const cartItem = this.items.find((item) => item.cartId == cartId)
      const membersOnlyCartItems = this.items.filter((item) => !!item.membersOnly)

      if (cartItem.type === 'membership' && membersOnlyCartItems.length > 0) {
        // XXX TODO – in the future maybe show an alert to users that removing membership will remove membersOnly items
        const userStore = useUserStore()

        const otherMemberships = this.items.filter((item) => item.type === 'membership' && item.cartId !== cartId)

        // if (otherMemberships.length === 0) { //  && !userStore.isActiveMember
        // there are no other memberships in the cart, remove membersOnly items
        if (userStore.isActiveMember) {
          const newItems = this.items.filter((item) => item.cartId !== cartId)
          this.items = newItems
        } else if (otherMemberships.length === 0) {
          const newItems = this.items.filter((item) => item.cartId !== cartId && !item.membersOnly)
          this.items = newItems
        }
        // }
      } else {
        const newItems = this.items.filter((item) => item.cartId !== cartId && item.parentId !== cartId)
        this.items = newItems

        if (siteConfig.key === 'snowfarm') {
          const classesInCart = this.items.filter(
            (item) => item.type === 'class' && item.personId == cartItem.personId && !item.joinWaitlist
          )
          const regFeeInCart = this.items.find(
            (item) => item.title === 'Registration Fee' && item.personId === cartItem.personId
          )

          if (classesInCart.length === 0 && regFeeInCart) {
            regFeeInCart.removable = true
          }
        }
      }

      if (cartItem.type === 'membership') {
        useClassStore().updateMembershipPricing()
      }

      if (cartItem.type === 'exhibition') {
        this.updateExhibitionPricing()
      }

      this.calculateShippingPrice()
      this.saveItemsToLocalStorge()
    },
    emptyCart() {
      this.items = []
      this.discountCode = null
      this.discounts = []
      localStorage.removeItem(`${siteConfig.key}Cart`)
    },
    filterOutBadItems() {
      const classStore = useClassStore()

      // 5 days in milliseconds
      const maxAge = 5 * 24 * 60 * 60 * 1000

      const filteredItems = this.items.filter((item) => {
        // First, get rid of any items older than 5 days
        const now = new Date()
        const timestamp = new Date(item.cartId)
        const diff = now - timestamp

        // console.log('now', now, 'timestamp', timestamp, 'diff', diff, 'maxAge', maxAge )
        if (diff > maxAge) {
          return false
        }

        // remove any class not currently in the catalog
        if (!classStore.loading && item.type === 'class' && !item.override) {
          const cls = classStore.filteredList.find((c) => (c.catalog_id = item.catalogId))
          // console.log('cls', cls?.shortTitle)
          if (!cls) {
            return false
          }
        }
        return true
      })

      if (this.items.length != filteredItems.length) {
        this.items = filteredItems
        this.saveItemsToLocalStorge()
      }
    },
    toggleCart(value) {
      this.openedCart = value
    },
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useCartStore, import.meta.hot))
}
